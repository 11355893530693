import * as React from 'react';

const IconTwitter = () => (
	<svg width="24px" height="19px" viewBox="0 0 24 19">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-531.000000, -259.000000)" fill="#000000">
				<path
					d="M533,260 C534.573913,262.444998 538.171826,264.751996 543,264.999998 C542.733913,263.937997 542.707826,261.056999 545,260 C545.754783,259.324 546.645913,259 548,259 C549.015652,259 550.097739,259.434 551,260 C552.187826,260.356999 553.938783,259.636 554,259 C554.083826,260.346999 553.001739,261.623998 552,261.999999 C552.937043,261.923998 554.51687,261.513998 555,260.999999 C554.329043,262.303998 553.157217,263.293997 553,263.999998 C553.258435,270.074993 547.333565,277.991988 539,277.999994 C536.395826,278.001988 533.755826,277.507988 531,275.999995 C533.718261,276.304989 536.631652,275.46099 538,273.999995 C536.195478,273.953991 534.058435,272.591991 533,269.999997 C534.144,270.814993 535.118609,270.974992 536,269.999997 C534.711652,270.393993 531.521739,268.719994 532,264.999998 C532.255304,266.058996 533.310261,266.659995 534,265.999998 C532.787478,265.737996 530.837217,262.836998 533,260 Z"
					id="Stroke-84"
				/>
			</g>
		</g>
	</svg>
);

export default IconTwitter;
