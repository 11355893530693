import * as React from 'react';

const IconPrint = () => (
	<svg width="35px" height="34px" viewBox="0 0 35 34">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-775.000000, -252.000000)">
				<g transform="translate(775.000000, 252.000000)">
					<path
						d="M34,24 L34,7 L1,7 L1,24 L27.8703704,24 L34,24 Z"
						id="Rectangle-11"
						stroke="#000000"
						strokeWidth="2"
					/>
					<circle id="Oval-3" fill="#000000" cx="30" cy="12" r="2" />
					<rect
						id="Rectangle-11-Copy"
						stroke="#000000"
						fill="#fff"
						strokeWidth="2"
						x="7"
						y="1"
						width="21"
						height="6"
					/>
					<rect
						id="Rectangle-11-Copy-2"
						stroke="#000000"
						fill="#fff"
						strokeWidth="2"
						x="7"
						y="17"
						width="21"
						height="16"
					/>
					<rect
						id="Rectangle-12"
						fill="#000000"
						x="11"
						y="21"
						width="13"
						height="2"
					/>
					<rect
						id="Rectangle-12"
						fill="#000000"
						x="11"
						y="25"
						width="13"
						height="2"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default IconPrint;
