import * as React from 'react';

const IconArrowRight = props => (
	<svg width="26px" height="25px" viewBox="0 0 26 25" {...props}>
		<g
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
		>
			<g
				transform="translate(-671.000000, -524.000000)"
				stroke="#000000"
				strokeWidth="4"
			>
				<g transform="translate(684.500000, 536.000000) rotate(-90.000000) translate(-684.500000, -536.000000) translate(674.000000, 525.000000)">
					<path d="M20.0651087,12 L11,21.0651087" id="Line-2" />
					<path d="M10,0 L10,21.3592264" id="Line-2" />
					<path
						d="M9.06510872,12 L0,21.0651087"
						id="Line-2"
						transform="translate(4.532554, 16.532554) scale(-1, 1) translate(-4.532554, -16.532554) "
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default IconArrowRight;
