import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import PageCreativeCommon from '../components/PageCreativeCommon';
import Tile from '../components/Tile';
import TopBar from '../components/TopBar';
import IconArrowRight from '../components/IconArrowRight';
import { getPageUrl } from '../utils';

import { Link } from 'gatsby';

import styled, { css } from 'styled-components';
import { knowledgePrimary } from '../contants/colors';

const StyledContainer = styled('div')`
	border-top: 6px solid ${knowledgePrimary};

	*::selection {
		background: ${knowledgePrimary};
		color: #000;
	}

	p a {
		color: ${knowledgePrimary};
	}

	@media print {
		border-top: 0;
	}
`;

const StyledInnerContainer = styled('div')`
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 2.5em;
`;

const StyledPurpose = styled('p')`
	font-weight: 200;
	font-size: 1.7em;
	max-width: 850px;
	margin: 1em auto;
	line-height: 1.3em;

	@media print {
		font-size: 1em;
	}
`;

const StyledContent = styled('div')`
	max-width: 850px;
	margin: 0 auto;
	font-size: 1.2em;
	line-height: 1.4em;

	h2,
	h3,
	h4,
	h5 {
		margin: 3em 0 1.5em;
		font-family: 'Work Sans', sans-serif;
	}

	li {
		margin-top: 1.2em;
	}

	a {
		color: ${knowledgePrimary};
	}

	.image-with-legend {
		display: flex;
		margin-top: 30px;

		& > :first-child {
			flex-grow: 1;
		}

		img {
			object-fit: contain;
			width: 600px;
			flex-shrink: 0;
		}

		@media (max-width: 800px) {
			flex-direction: column;
		}

		dl {
			font-size: 0.7em;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			max-width: 300px;

			@media (max-width: 800px) {
				max-width: 100%;
			}
		}

		dt {
			text-transform: uppercase;
			line-height: 1.2em;
		}
	}

	@media print {
		img,
		.gatsby-resp-image-link {
			display: none !important;
		}
	}
`;

const StyledTilesContainer = styled('div')`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	text-align: center;

	a {
		color: #000;
	}
`;

const commonNavigationStyle = css`
	color: #000;
	text-decoration: none;
	display: flex;
	padding: 2em 1.5em;
	position: fixed;
	top: 30%;

	@media (max-width: 990px) {
		padding: 2em 0.5em;
	}

	@media (max-width: 480px) {
		position: absolute;
	}

	@media print {
		display: none;
	}
`;

const StyledPrevious = styled(Link)`
	${commonNavigationStyle};
	writing-mode: vertical-lr;
	color: #000;
	left: 0;
`;

const StyledNext = styled(Link)`
	${commonNavigationStyle};
	writing-mode: vertical-lr;
	color: #000;
	right: 0;
`;

const StyledArticle = styled('article')`
	display: flex;
`;

const StyledArticleContent = styled('div')`
	flex-grow: 1;
`;

const StyledDzialaniaContainer = styled('div')`
	max-width: 850px;
	margin: 3em auto;

	@media print {
		display: none;
	}
`;

const StyledHeader = styled('h3')`
	margin: 3em 0;
`;

const StyledRightArrowWrapper = styled('div')`
	margin-bottom: 1em;
	transform: scale(0.8);
`;

const StyledLeftArrowWrapper = styled('div')`
	margin-bottom: 1em;
	transform: scale(0.8) rotate(180deg);
`;

const StyledTileContainer = styled('div')`
	width: 45%;
	margin-bottom: 2em;

	@media (max-width: 480px) {
		width: 100%;
	}
`;

export default ({ data, pageContext: { prev, next } }) => {
	const {
		frontmatter: { category, title, subtitle, slug, lead, illustrator },
		html,
		htmlPreview,
		fields: { dzialania } = {}
	} = data.markdownRemark;
	return (
		<Layout>
			<Helmet>
				<html lang="pl" />
				<title>{title} - do dizajnu</title>
				<meta property="og:description" content={lead} />
				<meta
					property="og:url"
					content={`https://dodizajnu.pl${getPageUrl(
						category,
						slug
					)}`}
				/>
				<meta name="description" content={lead} />
				<meta property="fb:app_id" content="328106031106735" />
				<meta property="og:type" content="article" />
				<meta property="og:locale" content="pl_PL" />
				<meta property="og:title" content={`${title} - do dizajnu`} />
			</Helmet>
			<StyledContainer>
				<TopBar />
				<StyledArticle>
					{prev && (
						<StyledPrevious to={`/${prev.fields.slug}`}>
							<StyledLeftArrowWrapper>
								<IconArrowRight />
							</StyledLeftArrowWrapper>
							{prev.frontmatter.title}
						</StyledPrevious>
					)}
					<StyledArticleContent>
						<PageHeader
							title={title}
							category={category}
							subtitle={subtitle}
							primaryColor={knowledgePrimary}
							illustrator={illustrator}
						/>
						<div>
							<StyledInnerContainer>
								<StyledPurpose>{lead}</StyledPurpose>
							</StyledInnerContainer>
						</div>
						<StyledInnerContainer>
							<StyledContent>
								{html && (
									<div
										dangerouslySetInnerHTML={{
											__html: html
										}}
									/>
								)}
								{htmlPreview}
							</StyledContent>
							{dzialania && (
								<StyledDzialaniaContainer>
									<StyledHeader>Więcej</StyledHeader>
									<StyledTilesContainer>
										{dzialania.map(dzialanie => (
											<StyledTileContainer
												key={dzialanie.frontmatter.slug}
											>
												<Tile
													size="relative"
													{...dzialanie.frontmatter}
												/>
											</StyledTileContainer>
										))}
									</StyledTilesContainer>
								</StyledDzialaniaContainer>
							)}
							<PageCreativeCommon />
						</StyledInnerContainer>
					</StyledArticleContent>
					{next && (
						<StyledNext to={`/${next.fields.slug}`}>
							<StyledRightArrowWrapper>
								<IconArrowRight />
							</StyledRightArrowWrapper>
							{next.frontmatter.title}
						</StyledNext>
					)}
				</StyledArticle>
				<Footer />
			</StyledContainer>
		</Layout>
	);
};
