import * as React from 'react';

const IconPdf = () => (
	<svg width="24px" height="43px" viewBox="0 0 24 43">
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g id="pdf" transform="translate(0.000000, -1.000000)">
				<g
					id="Group-6-Copy"
					transform="translate(11.000000, 13.500000) rotate(-180.000000) translate(-11.000000, -13.500000) translate(1.000000, -0.000000)"
				>
					<g id="Group-7">
						<rect
							id="Rectangle-3"
							fill="#000000"
							x="9"
							y="6"
							width="2"
							height="20"
						/>
						<rect
							id="Rectangle-3-Copy"
							fill="#000000"
							transform="translate(10.000000, 1.003906) rotate(-90.000000) translate(-10.000000, -1.003906) "
							x="9.00000025"
							y="-8.996094"
							width="2"
							height="20"
						/>
						<polyline
							id="Rectangle-9"
							stroke="#000000"
							strokeWidth="2"
							transform="translate(10.031291, 12.514688) rotate(-45.000000) translate(-10.031291, -12.514688) "
							points="4.85841622 7.34181372 14.4893261 8.01240098 15.204165 17.6875625"
						/>
					</g>
				</g>
				<path
					d="M0.21,34.396 L3.64,34.396 C4.60133814,34.396 5.34333072,34.6619973 5.866,35.194 C6.38866928,35.7260027 6.65,36.421329 6.65,37.28 C6.65,37.8773363 6.52400126,38.4023311 6.272,38.855 C6.01999874,39.3076689 5.66766893,39.6576654 5.215,39.905 C4.76233107,40.1523346 4.23733632,40.276 3.64,40.276 L2.002,40.276 L2.002,44 L0.21,44 L0.21,34.396 Z M3.458,38.694 C3.88733548,38.694 4.2186655,38.5703346 4.452,38.323 C4.6853345,38.0756654 4.802,37.7280022 4.802,37.28 C4.802,36.887998 4.68300119,36.5753345 4.445,36.342 C4.20699881,36.1086655 3.8780021,35.992 3.458,35.992 L2.002,35.992 L2.002,38.694 L3.458,38.694 Z M8.968,34.396 L12.09,34.396 C13.3033394,34.396 14.2483299,34.7926627 14.925,35.586 C15.60167,36.3793373 15.94,37.5366591 15.94,39.058 C15.94,40.6726747 15.6040034,41.8999958 14.932,42.74 C14.2599966,43.5800042 13.3126728,44 12.09,44 L8.968,44 L8.968,34.396 Z M11.922,42.404 C12.6033367,42.404 13.1353314,42.1496692 13.518,41.641 C13.9006686,41.1323308 14.092,40.3880049 14.092,39.408 L14.092,38.708 C14.092,37.8213289 13.9006686,37.1470023 13.518,36.685 C13.1353314,36.2229977 12.6033367,35.992 11.922,35.992 L10.76,35.992 L10.76,42.404 L11.922,42.404 Z M18.356,34.396 L23.998,34.396 L23.998,35.992 L20.148,35.992 L20.148,38.414 L23.438,38.414 L23.438,40.01 L20.148,40.01 L20.148,44 L18.356,44 L18.356,34.396 Z"
					id="PDF"
					fill="#000000"
					fillRule="nonzero"
				/>
			</g>
		</g>
	</svg>
);

export default IconPdf;
