import React from 'react';
import { graphql } from 'gatsby';
import WiedzaPage from '../components/WiedzaPage';

export default props => {
	return <WiedzaPage {...props} />;
};

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			fields {
				dzialania {
					frontmatter {
						title
						category
						subtitle
						slug
						thumbnail {
							childImageSharp {
								sizes(maxWidth: 800) {
									src
									srcSet
								}
							}
						}
						image {
							childImageSharp {
								sizes(maxWidth: 800) {
									src
									srcSet
								}
							}
						}
					}
				}
			}
			frontmatter {
				title
				subtitle
				lead
				category
				slug
				illustrator
			}
		}
	}
`;
