import * as React from 'react';
import styled from 'styled-components';
import IconFacebook from '../components/IconFacebook';
import IconMail from '../components/IconMail';
import IconTwitter from '../components/IconTwitter';
import IconPrint from '../components/IconPrint';
import IconPdf from '../components/IconPdf';
import Tooltip from '../components/Tooltip';

const StyledTitle = styled('h1')`
	margin-bottom: 0.5em;
`;

const StyledCategory = styled('p')`
	margin: 0;
	margin-bottom: 0.8em;
	text-transform: uppercase;
	font-size: 0.8em;
`;

const StyledButton = styled('button')`
	padding: 0.3em 1em;
	height: 60px;
	border: 0;
	background: 0;
	cursor: pointer;
`;

const StyledLink = styled('a')`
	padding: 1em;
`;

const StyledIcons = styled('div')`
	padding: 40px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;

	@media (max-width: 580px) {
		flex-direction: column;
	}

	@media print {
		display: none;
	}
`;

const StyledHeader = styled('header')`
	text-align: center;
`;

const StyledSubtitle = styled('h3')`
	font-size: 0.8em;
	font-weight: normal;
`;

const StyledInnerContainer = styled('div')`
	max-width: 1100px;
	margin: 0 auto;
`;

const StyledSecondIconContainer = styled('div')`
	border-left: 1px solid #000;
	margin-left: 40px;
	padding-left: 35px;

	@media (max-width: 580px) {
		border-left: 0;
		border-top: 1px solid #000;
		padding-left: 0;
		margin-left: 0;
		width: 80%;
		padding-top: 0.5em;
	}
`;

const StyledFirstIconContainer = styled('div')``;

const handlePrintClick = () => window.print();
const handleFbClick = () =>
	window.FB.ui(
		{
			method: 'share',
			href: window.location.href
		},
		response => {}
	);

const getIllustratorPhrase = illustrator => {
	const [name] = illustrator.split(' ');
	if (name.slice(-1) === 'a') {
		return 'przygotowała';
	}
	return 'przygotował';
};

const PageHeader = ({
	title,
	category,
	subtitle,
	primaryColor,
	illustrator
}) => (
	<StyledHeader>
		<StyledInnerContainer>
			<StyledCategory>{category}</StyledCategory>
			<StyledTitle>{title}</StyledTitle>
			<StyledSubtitle>{subtitle}</StyledSubtitle>
			{illustrator && (
				<StyledSubtitle>
					ilustracje {getIllustratorPhrase(illustrator)} {illustrator}
				</StyledSubtitle>
			)}
			<StyledIcons>
				<StyledFirstIconContainer>
					<Tooltip
						label="Udostępnij na Facebooku"
						style={{
							backgroundColor: primaryColor,
							fill: primaryColor
						}}
					>
						<StyledButton onClick={handleFbClick}>
							<IconFacebook />
						</StyledButton>
					</Tooltip>
					<Tooltip
						label="Udostępnij na Twiterze"
						style={{
							backgroundColor: primaryColor,
							fill: primaryColor
						}}
					>
						<StyledLink
							href={`http://twitter.com/share?text=${title}&url=${
								typeof window !== 'undefined'
									? window.location.href
									: ''
							}&hashtags=dizajn,dodizajnu,dodziela`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<IconTwitter />
						</StyledLink>
					</Tooltip>
					<Tooltip
						label="Wyślij mailem"
						style={{
							backgroundColor: primaryColor,
							fill: primaryColor
						}}
					>
						<StyledLink
							href={`mailto:?subject=Do dzieła&body=${title} ${
								typeof window !== 'undefined'
									? window.location.href
									: ''
							}`}
						>
							<IconMail />
						</StyledLink>
					</Tooltip>
				</StyledFirstIconContainer>
				<StyledSecondIconContainer>
					<Tooltip
						label="Drukuj"
						style={{
							backgroundColor: primaryColor,
							fill: primaryColor
						}}
					>
						<StyledButton onClick={handlePrintClick}>
							<IconPrint />
						</StyledButton>
					</Tooltip>
					<Tooltip
						label="Pobierz PDF"
						style={{
							backgroundColor: primaryColor,
							fill: primaryColor
						}}
					>
						<StyledButton onClick={handlePrintClick}>
							<IconPdf />
						</StyledButton>
					</Tooltip>
				</StyledSecondIconContainer>
			</StyledIcons>
		</StyledInnerContainer>
	</StyledHeader>
);

export default PageHeader;
