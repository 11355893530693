import * as React from 'react';
import { findDOMNode } from 'react-dom';

const TOOLTIP_OFFSET = 10;
const ARROW_WIDTH = 6;

const getTooltipStyle = ({ left, right, top }) => ({
	display: 'inline-block',
	backgroundColor: '#424d57',
	color: '#fff',
	padding: '10px',
	position: 'fixed',
	zIndex: '9',
	fontSize: '0.8em',
	whiteSpace: 'nowrap',
	fontWeight: 'normal',
	marginTop: '0.5em',
	fill: '#424d57',
	left: left ? left - TOOLTIP_OFFSET + 'px' : 'auto',
	right: right ? right - TOOLTIP_OFFSET + 'px' : 'auto',
	top: top + 'px' || 'auto',
	border: '1px solid #000',
	lineHeight: '1em'
});

const getArrowStyle = ({ left, right, arrowHorizontalFactor }) => ({
	position: 'fixed',
	fill: 'inherit',
	marginTop: '-19.5px',
	left: left
		? left - TOOLTIP_OFFSET + ARROW_WIDTH / 2 + arrowHorizontalFactor + 'px'
		: 'auto',
	right: right
		? right -
		  TOOLTIP_OFFSET +
		  ARROW_WIDTH / 2 +
		  arrowHorizontalFactor +
		  'px'
		: 'auto',
	top: 'auto'
});

class Tooltip extends React.Component {
	state = {
		hover: false,
		hoverTooltip: false,
		disabled: false
	};

	disable = () => {
		this.setState({
			disabled: true
		});
	};

	setHoverTooltip = () => {
		this.setState({
			hoverTooltip: true
		});
	};

	unsetHoverTooltip = () => {
		this.setState({
			hoverTooltip: false
		});
	};

	childrenRefs = [];

	setHover = () => {
		if (this.state.disabled || this.props.disabled) {
			return;
		}
		const boundingClientRect = this.childrenRefs.reduce((acc, next) => {
			const newAcc = { ...acc };
			const nextBoundingClientRect = next.getBoundingClientRect();
			newAcc.left =
				typeof acc.left === 'undefined'
					? nextBoundingClientRect.left
					: Math.min(acc.left, nextBoundingClientRect.left);
			newAcc.right =
				typeof acc.right === 'undefined'
					? nextBoundingClientRect.right
					: Math.max(acc.right, nextBoundingClientRect.right);
			newAcc.bottom =
				typeof acc.bottom === 'undefined'
					? nextBoundingClientRect.bottom
					: Math.max(acc.bottom, nextBoundingClientRect.bottom);
			return newAcc;
		}, {});

		const position = {
			top: boundingClientRect.bottom
		};

		if (
			boundingClientRect.left + window.pageXOffset >
			window.innerWidth / 2
		) {
			position.right =
				window.innerWidth -
				(boundingClientRect.right + window.pageXOffset);
		} else {
			position.left = boundingClientRect.left + window.pageXOffset;
		}

		this.setState({
			hover: true,
			position,
			targetWidth:
				this.childrenRefs[0] && this.childrenRefs[0].offsetWidth
		});
	};
	unsetHover = () => {
		this.setState({
			hover: false
		});
	};
	registerRef = ref => {
		this.childrenRefs.push(
			ref instanceof HTMLElement ? ref : findDOMNode(ref)
		);
	};
	render() {
		const { children, label, style, ...props } = this.props;
		const parsedChildren = React.Children.map(
			this.props.children,
			child => {
				return React.cloneElement(child, {
					ref: this.registerRef,
					onMouseEnter: this.setHover,
					onMouseLeave: this.unsetHover,
					onTouchStart: this.disable
				});
			}
		);
		return (
			<React.Fragment>
				{parsedChildren}

				{(this.state.hover || this.state.hoverTooltip) && (
					<div
						style={{
							...getTooltipStyle(this.state.position),
							...style
						}}
						onMouseEnter={this.setHoverTooltip}
						onMouseLeave={this.unsetHoverTooltip}
						{...props}
					>
						<svg
							height="10"
							width="16"
							style={getArrowStyle({
								...this.state.position,
								arrowHorizontalFactor:
									this.state.targetWidth / 2
								// arrowHorizontalFactor: 10
							})}
						>
							<polygon points="0,10 16,10 8,0" />
							<path
								fill="none"
								stroke="#000"
								strokeWidth="1"
								d="M 0,10 L 8,0 L 16,10"
							/>
						</svg>
						{label}
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default Tooltip;
