import * as React from 'react';
import styled from 'styled-components';
import IconNonCommercial from '../components/IconNonCommercial';
import IconNoDerivativeWorks from '../components/IconNoDerivativeWorks';
import IconAttribution from '../components/IconAttribution';

const StyledContainer = styled('div')`
	border-top: 1px solid #000;
	padding: 20px 0;
	display: flex;
	align-items: flex-end;
	margin-top: 70px;
	max-width: 850px;
	margin: 2em auto;

	svg {
		width: 40px;
		height: 40px;
		margin-right: 20px;

		@media print {
			display: none;
		}
	}
`;

const StyledP = styled('p')`
	font-size: 0.9em;
	margin: 0;
	line-height: 1em;
	margin-left: 20px;
`;

const PageCreativeCommon = () => (
	<StyledContainer>
		<IconAttribution />
		<IconNonCommercial />
		<IconNoDerivativeWorks />
		<StyledP>
			Uznanie autorstwa - Użycie niekomercyjne - Bez utworów zależnych 3.0
			Polska{' '}
		</StyledP>
	</StyledContainer>
);

export default PageCreativeCommon;
