import * as React from 'react';

const IconMail = () => (
	<svg width="27px" height="18px" viewBox="0 0 27 18">
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="mail"
				transform="translate(-590.000000, -259.000000)"
				stroke="#000000"
				strokeWidth="2"
			>
				<g transform="translate(591.000000, 260.000000)">
					<rect id="Rectangle-9" x="0" y="0" width="25" height="16" />
					<path
						d="M1,5 C4.83333333,9.3333333 8.66666667,11.5 12.5,11.5 C16.3333333,11.5 20.1666667,9.3333333 24,5"
						id="Line-3"
						strokeLinecap="square"
					/>
				</g>
			</g>
		</g>
	</svg>
);

export default IconMail;
